/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import createGet from '../utils/create-get';

import headerDiscoveryModuleSvg from '../img/headers/header-discovery-module.svg';
import headerDevelopModuleSvg from '../img/headers/header-develop-module.svg';
import headerDecisionModuleSvg from '../img/headers/header-decision-module.svg';
import headerDecisionModuleFrSvg from '../img/headers/header-decision-module-fr.svg';

import discoveryModuleCalloutSvg from '../img/illustrations/module-discovery-callout.svg';
import developModuleCalloutSvg from '../img/illustrations/module-develop-callout.svg';
import decisionModulCallouteSvg from '../img/illustrations/module-decision-callout.svg';

import moduleDiscoveryIntegrationSvg from '../img/illustrations/module-discovery-integration.svg';
import moduleDiscoveryIntegrationFrSvg from '../img/illustrations/module-discovery-integration-fr.svg';
import moduleDiscoveryExplorationSvg from '../img/illustrations/module-discovery-exploration.svg';
import moduleDiscoveryVisualizationSvg from '../img/illustrations/module-discovery-visualization.svg';

import moduleDevelopWorkflowSvg from '../img/illustrations/module-develop-workflow.svg';
import moduleDevelopInnovationSvg from '../img/illustrations/module-develop-innovation.svg';
import moduleDevelopExecutionSvg from '../img/illustrations/module-develop-execution.svg';

import moduleDecisionSupportSvg from '../img/illustrations/module-decision-support.svg';
import moduleDecisionSupportFrSvg from '../img/illustrations/module-decision-support-fr.svg';
import moduleDecisionCollaborationSvg from '../img/illustrations/module-decision-collaboration.svg';
import moduleDecisionCollaborationFrSvg from '../img/illustrations/module-decision-collaboration-fr.svg';

import Layout from '../components/Layout';
import Container from '../components/Container2';
import MarkdownContent from '../components/MarkdownContent';
import Callout from '../components/Callout';
import AspectRatioImg from '../components/AspectRatioImg';

const headerMap = {
  discovery: headerDiscoveryModuleSvg,
  develop: headerDevelopModuleSvg,
  decision: headerDecisionModuleSvg,
  'decision-fr': headerDecisionModuleFrSvg,
};

const calloutMap = {
  discovery: discoveryModuleCalloutSvg,
  develop: developModuleCalloutSvg,
  decision: decisionModulCallouteSvg,
  'decision-fr': decisionModulCallouteSvg,
};

const imageMap = {
  integration: moduleDiscoveryIntegrationSvg,
  'integration-fr': moduleDiscoveryIntegrationFrSvg,
  exploration: moduleDiscoveryExplorationSvg,
  visualization: moduleDiscoveryVisualizationSvg,
  workflow: moduleDevelopWorkflowSvg,
  innovation: moduleDevelopInnovationSvg,
  execution: moduleDevelopExecutionSvg,
  support: moduleDecisionSupportSvg,
  'support-fr': moduleDecisionSupportFrSvg,
  collaboration: moduleDecisionCollaborationSvg,
  'collaboration-fr': moduleDecisionCollaborationFrSvg,
};

const SectionIllustration = ({ imageKey }) => (
  <div sx={{ display: ['none', null, 'block'] }}>
    <AspectRatioImg src={imageMap[imageKey]} alt="" ratio={440 / 429} />
  </div>
);

const SectionContent = ({ get, section }) => (
  <div>
    <Styled.h3>{get(section, 'title')}</Styled.h3>
    <MarkdownContent sx={{ mt: 7 }}>{get(section, 'text')}</MarkdownContent>
  </div>
);

export const ModulePageTemplate = ({ get }) => (
  <section>
    <Container>
      <AspectRatioImg
        src={headerMap[get('imageKey')]}
        alt=""
        ratio={912 / 256}
      />
      <Styled.h1 sx={{ mt: 13 }}>{get('title')}</Styled.h1>
      <Styled.p sx={{ mt: 4, fontWeight: 'bold' }}>{get('subtitle')}</Styled.p>
      <MarkdownContent sx={{ mt: 7 }}>{get('text')}</MarkdownContent>
      <div
        sx={{
          display: 'grid',
          gridAutoFlow: 'rows',
          gridGap: 14,
          mt: 13,
        }}
      >
        {get('sections', []).map((section, i) => (
          <article
            key={i}
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, '1fr 1fr'],
              gridGap: 7,
            }}
          >
            {i % 2 === 0 ? (
              <Fragment>
                <SectionIllustration imageKey={get(section, 'imageKey')} />
                <SectionContent get={get} section={section} />
              </Fragment>
            ) : (
              <Fragment>
                <SectionContent get={get} section={section} />
                <SectionIllustration imageKey={get(section, 'imageKey')} />
              </Fragment>
            )}
          </article>
        ))}
      </div>
      <Callout sx={{ mt: 14 }}>
        <div
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '2fr 1fr'],
            gridGap: 9,
            alignItems: 'center',
          }}
        >
          <div>
            <Styled.h2>{get('callout.title')}</Styled.h2>
            <MarkdownContent sx={{ mt: 7 }}>
              {get('callout.text')}
            </MarkdownContent>
          </div>
          <div sx={{ display: ['none', 'block'] }}>
            <AspectRatioImg
              src={calloutMap[get('imageKey')]}
              alt=""
              ratio={251 / 330}
            />
          </div>
        </div>
      </Callout>
    </Container>
  </section>
);

const ModulePage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;

  return (
    <Layout
      page={frontmatter.moduleKey}
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <ModulePageTemplate get={createGet(frontmatter)} />
    </Layout>
  );
};

ModulePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ModulePage;

export const pageQuery = graphql`
  query ModulePageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
        title
        subtitle
        moduleKey
        imageKey
        text
        sections {
          title
          text
          imageKey
        }
        callout {
          title
          text
        }
      }
    }
  }
`;
